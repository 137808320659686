<template>
  <div class="security1">
    <div class="f_tit basefont30">
        账户与安全
    </div>
    <div class="cont">
        <form action="" class="form" >
            <div class="f_item">
                <div class="name">手机号</div>
                <div class="f_jut" key="1">
                    <div class="int">
                        <template v-if="nicheng">
                            <input type="text" disabled v-model="phonName.userPhone" placeholder="">
                        </template>
                        <template v-else>
                           <input type="text" class="fzint" v-model="phonName.userPhone" placeholder="">
                        </template>
                        <!-- <a href="javascript:;" class="xiu" @click="phonFocus()"><img src="../../assets/images/xiugaiicom1.png" alt=""></a> -->
                    </div>
                </div>
            </div>
           
            <div class="f_item code1">
                <div class="name">验证码:</div>
                <div class="f_jut">
                    <div class="int">
                        <input type="text" autocomplete="off" v-model="form.code" placeholder="" value="">
                        <a href="javascript:;" @click="codeSend()" class="yan">{{codesen}}</a>
                    </div>
                </div>
            </div>

             <div class="f_item">
                <div class="name">登录密码</div>
                <div class="f_jut" key="2">
                    <div class="int">
                        <template v-if="pas">
                            <input type="password" readonly  onfocus="this.removeAttribute('readonly')" autocomplete="off" disabled v-model="form.password" placeholder="">
                        </template>
                        <template v-else>
                           <input type="password" readonly  onfocus="this.removeAttribute('readonly')" autocomplete="off" class="fzint2" v-model="form.password" placeholder="">
                        </template>
                        <!-- <a href="javascript:;" class="xiu"><img src="../../assets/images/xiugaiicom1.png" alt=""></a> -->
                    </div>
                </div>
            </div>
             <div class="f_item">
                <div class="name">重复密码</div>
                <div class="f_jut" key="2">
                    <div class="int">
                        <template v-if="pas">
                            <input type="password" readonly  onfocus="this.removeAttribute('readonly')" autocomplete="off" disabled v-model="form.password2" placeholder="">
                        </template>
                        <template v-else>
                           <input type="password"  readonly onfocus="this.removeAttribute('readonly')" autocomplete="off" class="fzint2" v-model="form.password2" placeholder="">
                        </template>
                        <!-- <a href="javascript:;" class="xiu"><img src="../../assets/images/xiugaiicom1.png" alt=""></a> -->
                    </div>
                </div>
            </div>
            <!-- <div class="f_item guanlian">
                <div class="name">账号关联</div>
                <div class="f_jut">
                    <div class="f_mei">
                        <a href="javascript:;" class="li">
                            <img src="../../assets/images/zhanghuicnimg1.png" alt="">
                        </a>
                        <a href="javascript:;" class="li">
                            <img src="../../assets/images/zhanghuicnimg2.png" alt="">
                        </a>
                    </div>
                    <a href="javascript:;" class="jiechu">点击图标解除关联</a>
                </div>
            </div> -->
            <button type="submit" @click.prevent="safetySubmit()" class="hm-but f_sub">
                <span>保存修改</span>
                <i>
                    <img src="../../assets/images/jiantouyo1.png" >
                    <img src="../../assets/images/jiantouyo2.png" >
                </i>
            </button>
        </form>
    </div>
  </div>
</template>

<script>
import { mapState  } from 'vuex';
import { MD5 } from 'crypto-js';

export default {
  name: "Mysecurity",
  data(){
    return {
        nicheng: true,
        pas: false,
        form: {
            password: "",
            code:"",
            password: "",
            password2:"",
        },
        codesen:"发送验证码",
        codeswitch: true,
    }
  },
  mounted(){
    
  },
  computed:{
    ...mapState({
        phonName: state => state.UserInfo.accountMessage
    })
  },
  methods:{
    /* 账号获取焦点 */
    phonFocus(){
        this.nicheng = !this.nicheng
        if(this.nicheng==false){
            setTimeout(()=>{
                document.querySelector(".fzint").focus();
            },200);
        }
    },
    /* 密码获取焦点 */
    pasFocus(){
        this.pas = !this.pas;
        if(this.pas==false){
            setTimeout(()=>{
                document.querySelector(".fzint2").focus();
            },200);
        }
    },
    /* 手机号码验证 */
    telCode:function(){
        var re = /^1\d{10}$/;
        if(this.phonName.userPhone==""){
            this.$message({
                message: '手机号码不能为空',
                type: 'error',
            });
            return false;
        }
        if(re.test(this.phonName.userPhone)){
            return true;
        }else{
            this.$message({
                message: '手机号码输入错误',
                type: 'error',
            });
            return false;
        }
    },
    // MD5加密
    passwordMD5(mess){
        return MD5(mess).toString().toUpperCase()
    },
    /* 账户与安全提交 */
    safetySubmit(){
        var pwdReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/; //8到16位数字与字母组合
        if(!this.telCode()){
            return false;
        }
        if(this.form.password == ""){
            this.$message({
                message: '密码不能为空',
                type: 'error',
            });
            return false;
        }
        if(this.form.password == ""){
            this.$message({
                message: '密码不能为空',
                type: 'error',
            });
            return false;
        }
        if(!pwdReg.test(this.form.password)){
            this.$message({
                message: "包含字母和数字,且长度为8-16个字符!",
                type: 'error',
            });
            return false;
        }
        if(this.form.password!=this.form.password2){
            this.$message({
                message: '两次密码不一致',
                type: 'error',
            });
            return false;
        }
        const tel = this.phonName.userPhone;
        this.$axios.post('/api2/portal/user/forgetPassword',{
                phone: tel,
                type: 0,
                verifyCode: this.form.code,
                password:  this.passwordMD5(this.form.password),
                confirmPassword: this.passwordMD5(this.form.password2),
            }).then(res => {
                console.log(res);
                if(res.code=="000000"){
                    // this.afteFadeIn();
                     this.$message({
                        message: '保存成功!',
                        type: 'success',
                    });
                    this.loginOut();
                    // this.$router.push({path: "/sign/passign"});
                }else{
                    this.$message({
                        message: res.message,
                        type: 'error',
                    });
                }
            }).catch(error => {
                console.log(error);
                this.$message({
                    message: '设置失败!',
                    type: 'error',
                });
            });
    },
    /* 发送接口 */
    codeSend:function(){
        if(!this.telCode()){
            return false;
        }
    //    if(this.confirmSuccess==false){
    //        this.$message({
    //             message: '请滑动验证',
    //             type: 'error',
    //         });
    //         return false;
    //    }
        this.$axios.post('/api2/portal/contact/sms/send',{
            phone: this.phonName.userPhone,
            type: "verifyCode",
        }).then(res => {
            console.log(res);
            if(res.code=="000000"){
                this.$message({
                    message: '发送成功!',
                    type: 'success',
                });
                if(this.codeswitch){
                    this.codeswitch=false;
                    var ti = 60;
                    var tiem;
                    tiem = setInterval(()=>{
                        ti--;
                        if(ti<=0){
                            this.codesen = "重新发送";
                            this.codeswitch = true;
                            clearInterval(tiem);
                            return false;
                        }
                        this.codesen="("+ti+")";
                    },1000);
                }
            }else{
                this.$message({
                    message: '发送失败!',
                    type: 'error',
                });
            }
        }).catch(error => {
            console.log(error);
            this.$message({
                message: '接口异常!',
                type: 'error',
            });
        });
        
    },
    /* 退出登录 */
		loginOut(){
			// 清除token
			var $token = this.$store.state.loginStore.login.token;
			console.log($token);
			this.$axios.post('/api2/portal/auth/user/logout',{token:$token}).then(res=>{
				console.log(res);
			}).catch(error=>{
				console.log(error);
			});
			window.localStorage.setItem("login",null);
			this.$router.push({path:"/sign/scancode"});
		}
  }
}
</script>
